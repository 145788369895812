/* barriecito-regular - latin */
@font-face {
  font-family: "Barriecito";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/barriecito-v2-latin-regular.eot"); /* IE9 Compat Modes */
  src:
    local("Barriecito Regular"),
    local("Barriecito-Regular"),
    url("./assets/fonts/barriecito-v2-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/barriecito-v2-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/barriecito-v2-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/barriecito-v2-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/barriecito-v2-latin-regular.svg#Barriecito")
      format("svg"); /* Legacy iOS */
}
/* livvic-100 - latin */
@font-face {
  font-family: "Livvic";
  font-style: normal;
  font-weight: 100;
  src: url("./assets/fonts/livvic-v3-latin-100.eot"); /* IE9 Compat Modes */
  src:
    local("Livvic Thin"),
    local("Livvic-Thin"),
    url("./assets/fonts/livvic-v3-latin-100.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/livvic-v3-latin-100.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/livvic-v3-latin-100.woff")
      format("woff"),
    /* Modern Browsers */ url("./assets/fonts/livvic-v3-latin-100.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/livvic-v3-latin-100.svg#Livvic") format("svg"); /* Legacy iOS */
}
/* livvic-100italic - latin */
@font-face {
  font-family: "Livvic";
  font-style: italic;
  font-weight: 100;
  src: url("./assets/fonts/livvic-v3-latin-100italic.eot"); /* IE9 Compat Modes */
  src:
    local("Livvic Thin Italic"),
    local("Livvic-ThinItalic"),
    url("./assets/fonts/livvic-v3-latin-100italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/livvic-v3-latin-100italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/livvic-v3-latin-100italic.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/livvic-v3-latin-100italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/livvic-v3-latin-100italic.svg#Livvic") format("svg"); /* Legacy iOS */
}
/* livvic-200 - latin */
@font-face {
  font-family: "Livvic";
  font-style: normal;
  font-weight: 200;
  src: url("./assets/fonts/livvic-v3-latin-200.eot"); /* IE9 Compat Modes */
  src:
    local("Livvic ExtraLight"),
    local("Livvic-ExtraLight"),
    url("./assets/fonts/livvic-v3-latin-200.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/livvic-v3-latin-200.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/livvic-v3-latin-200.woff")
      format("woff"),
    /* Modern Browsers */ url("./assets/fonts/livvic-v3-latin-200.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/livvic-v3-latin-200.svg#Livvic") format("svg"); /* Legacy iOS */
}
/* livvic-200italic - latin */
@font-face {
  font-family: "Livvic";
  font-style: italic;
  font-weight: 200;
  src: url("./assets/fonts/livvic-v3-latin-200italic.eot"); /* IE9 Compat Modes */
  src:
    local("Livvic ExtraLight Italic"),
    local("Livvic-ExtraLightItalic"),
    url("./assets/fonts/livvic-v3-latin-200italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/livvic-v3-latin-200italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/livvic-v3-latin-200italic.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/livvic-v3-latin-200italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/livvic-v3-latin-200italic.svg#Livvic") format("svg"); /* Legacy iOS */
}
/* livvic-regular - latin */
@font-face {
  font-family: "Livvic";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/livvic-v3-latin-regular.eot"); /* IE9 Compat Modes */
  src:
    local("Livvic Regular"),
    local("Livvic-Regular"),
    url("./assets/fonts/livvic-v3-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/livvic-v3-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/livvic-v3-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/livvic-v3-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/livvic-v3-latin-regular.svg#Livvic") format("svg"); /* Legacy iOS */
}
/* livvic-italic - latin */
@font-face {
  font-family: "Livvic";
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/livvic-v3-latin-italic.eot"); /* IE9 Compat Modes */
  src:
    local("Livvic Italic"),
    local("Livvic-Italic"),
    url("./assets/fonts/livvic-v3-latin-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/livvic-v3-latin-italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/livvic-v3-latin-italic.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/livvic-v3-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/livvic-v3-latin-italic.svg#Livvic") format("svg"); /* Legacy iOS */
}
/* livvic-600 - latin */
@font-face {
  font-family: "Livvic";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/livvic-v3-latin-600.eot"); /* IE9 Compat Modes */
  src:
    local("Livvic SemiBold"),
    local("Livvic-SemiBold"),
    url("./assets/fonts/livvic-v3-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/livvic-v3-latin-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/livvic-v3-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("./assets/fonts/livvic-v3-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/livvic-v3-latin-600.svg#Livvic") format("svg"); /* Legacy iOS */
}
/* livvic-600italic - latin */
@font-face {
  font-family: "Livvic";
  font-style: italic;
  font-weight: 600;
  src: url("./assets/fonts/livvic-v3-latin-600italic.eot"); /* IE9 Compat Modes */
  src:
    local("Livvic SemiBold Italic"),
    local("Livvic-SemiBoldItalic"),
    url("./assets/fonts/livvic-v3-latin-600italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/livvic-v3-latin-600italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/livvic-v3-latin-600italic.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/livvic-v3-latin-600italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/livvic-v3-latin-600italic.svg#Livvic") format("svg"); /* Legacy iOS */
}
/* livvic-900 - latin */
@font-face {
  font-family: "Livvic";
  font-style: normal;
  font-weight: 900;
  src: url("./assets/fonts/livvic-v3-latin-900.eot"); /* IE9 Compat Modes */
  src:
    local("Livvic Black"),
    local("Livvic-Black"),
    url("./assets/fonts/livvic-v3-latin-900.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/livvic-v3-latin-900.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/livvic-v3-latin-900.woff")
      format("woff"),
    /* Modern Browsers */ url("./assets/fonts/livvic-v3-latin-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/livvic-v3-latin-900.svg#Livvic") format("svg"); /* Legacy iOS */
}
/* livvic-900italic - latin */
@font-face {
  font-family: "Livvic";
  font-style: italic;
  font-weight: 900;
  src: url("./assets/fonts/livvic-v3-latin-900italic.eot"); /* IE9 Compat Modes */
  src:
    local("Livvic Black Italic"),
    local("Livvic-BlackItalic"),
    url("./assets/fonts/livvic-v3-latin-900italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/livvic-v3-latin-900italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/livvic-v3-latin-900italic.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/livvic-v3-latin-900italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/livvic-v3-latin-900italic.svg#Livvic") format("svg"); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 768px) {
  .hidden-sm {
    display: none;
  }
}
