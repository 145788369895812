@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}
$min-width: 320px;
$max-width: 1920px;

@mixin fluid-prop($prop, $min-vw, $max-vw, $min-size, $max-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-size);
  $u4: unit($max-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      #{$prop}: $min-size;
      @media screen and (min-width: $min-vw) {
        #{$prop}: calc(
          #{$min-size} + #{strip-unit($max-size - $min-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        #{$prop}: $max-size;
      }
    }
  }
}
.app .chart-container {
  background-color: white;
  border-radius: 5px;
  margin: 0 10%;
  max-width: 1200px;
  padding: 2vw;
  position: static;
  left: 5%;
  right: 5%;
  margin: 0 20px;
  z-index: 100;
  //&:hover {
  //z-index: 1000;
  //}
  &.chart-container--full-history {
    position: static;
  }
}
.recharts-responsive-container {
  min-height: 40vh;
  max-height: 40vh;
  max-width: 1200px;
  margin: auto;
}
.chart-padding {
  padding: 50px 0;
}
.chart-heading {
  text-align: center;
  @include fluid-prop("font-size", $min_width, $max_width, 32px, 64px);
  font-family: "Barriecito", "Livvic", sans-serif;
  margin: auto auto 0;
  line-height: 1;
  font-weight: 800;
  max-width: 700px;
  @media screen and (max-width: 768px) {
    max-width: 90%;
  }
  .per {
    font-size: 66%;
  }
}
.chart-subheading {
  text-align: center;
  margin-bottom: 100px;
  @include fluid-prop("font-size", $min_width, $max_width, 24px, 36px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
