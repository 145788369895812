//#fdd043 //yellow
//rgba(253, 208, 67, 1);
//#aa5c9f //light-purple
//rgba(170, 92, 159, 1);
//#7f4782 //dark-purple
//rgba(127, 71, 130,1);
//#fd8b7b //salmon
//rgba(253, 139, 123, 1);
//#e2598b //pink
//rgba(226, 89, 139, 1);

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}
$min-width: 320px;
$max-width: 1920px;

@mixin fluid-prop($prop, $min-vw, $max-vw, $min-size, $max-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-size);
  $u4: unit($max-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      #{$prop}: $min-size;
      @media screen and (min-width: $min-vw) {
        #{$prop}: calc(
          #{$min-size} + #{strip-unit($max-size - $min-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        #{$prop}: $max-size;
      }
    }
  }
}

.home__header {
  min-height: 75vh;
  max-height: 90vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(253, 208, 67, 0.7);
  background-image: linear-gradient(
    125deg,
    rgba(253, 208, 67, 0.2) 0%,
    rgba(226, 89, 139, 0.7) 100%
  );
  * {
    z-index: 1;
  }
  img {
    max-width: 66vw;
    animation: slide-in-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  h1 {
    @include fluid-prop("font-size", $min_width, $max_width, 28px, 90px);
    animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.61, 0.355, 1)
      both;
    margin: 0;
    line-height: 1;
    margin-top: -25px;
    font-family: "Barriecito", "Livvic", sans-serif;
    font-weight: 800;
    color: #7f4782;
  }

  .recharts-responsive-container {
    z-index: 0;
    height: 33vh !important;
    position: absolute;
    bottom: 0;
    max-width: unset;
  }
}
.header__btn-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 480px) {
    padding-top: 20px;
    flex-direction: column;
  }
  & > .btn-link {
    margin: 30px 20px;
    @media screen and (max-width: 480px) {
      margin: 10px 20px;
    }
  }
  .btn-link .btn {
    font-weight: 800;
    line-height: 1.2;
    @include fluid-prop("font-size", $min_width, $max_width, 22px, 36px);
    & > .sub-button {
      @include fluid-prop("font-size", $min_width, $max_width, 14px, 24px);
      font-weight: 400;
      line-height: 1;
    }
    &:hover {
      background-color: white;
      cursor: pointer;
    }
  }
  .btn-link--1 .btn {
    background-color: rgba(253, 139, 123, 1);
    border: 2px solid rgba(226, 89, 139, 1);
    &:hover {
      color: rgba(226, 89, 139, 1);
    }
  }
  .btn-link--demo .btn,
  .btn-link--2 .btn {
    background-color: rgba(170, 92, 159, 1);
    border: 2px solid rgba(127, 71, 130, 1);
    &:hover {
      color: rgba(127, 71, 130, 1);
    }
  }

  .btn-link--demo .btn {
    box-shadow:
      0 1px 5px rgba(0, 0, 0, 0.12),
      0 1px 5px rgba(0, 0, 0, 0.24);
    @include fluid-prop("font-size", $min_width, $max_width, 28px, 48px);
    line-height: 1;
    & * {
      margin: 0;
    }
  }

  .exit-btn {
    position: absolute;
    padding: 5%;
    margin: -15% -20%;
    color: white;
    border-radius: 50%;
    border: 3px solid #e2598b;
    background-color: #e2598b;
    transition:
      color 0.3s,
      background-color 0.3s;
    cursor: pointer;
    &:hover {
      background-color: white;
      color: #e2598b;
    }

    svg {
      height: 40px;
      width: 40px;
      @media screen and (max-width: 480px) {
        height: 10vw;
        width: 10vw;
      }
    }
  }
  .btn-link--demo {
    z-index: 9000;
    &,
    .btn {
      border-radius: 4px 0 0 0;
    }

    margin: 0;
    position: fixed;
    right: -100%;
    bottom: 0;
    transition: right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    &.entry {
      right: 0;
    }
  }
}

.css-logo {
  animation: slide-in-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  font-family: "Barriecito", "Livvic", sans-serif;
  @include fluid-prop("font-size", $min_width, $max_width, 150px, 600px);
  position: relative;
  text-align: center;
  line-height: 0.9;
}
.css-logo__wail {
  color: #aa5c9f;
  white-space: nowrap;
}
.css-logo__to {
  color: #fdd043;
  @include fluid-prop("font-size", $min_width, $max_width, 75px, 300px);
  position: absolute;
  right: 0;
  top: 20%;
  white-space: nowrap;
}
main.home {
  padding: 2vw 5vw 10vw;
  max-width: 1000px;
  margin: auto;
  font-family: "Livvic", sans-serif;

  h2 {
    @include fluid-prop("font-size", $min_width, $max_width, 42px, 90px);
    animation: tracking-in-contract--mini 0.8s
      cubic-bezier(0.215, 0.61, 0.355, 1) both;
    animation-delay: 1s;
    line-height: 1;
    font-weight: 800;
    color: #7f4782;
  }
  blockquote {
    font-family: "Livvic", sans-serif;
    border-left: 4px solid #e2598b;
    color: #e2598b;
  }
  blockquote,
  p {
    @include fluid-prop("font-size", $min_width, $max_width, 18px, 24px);
  }
  .body__p-header {
    @include fluid-prop("font-size", $min_width, $max_width, 28px, 36px);
    font-weight: 800;
    line-height: 1;
    margin-bottom: 10px;
    margin-top: 30px;
  }
}

.home__body__123 {
  margin-top: 50px;
}

.waiting {
  span {
    display: inline-block;
    animation-name: blink--home;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  span:nth-child(2) {
    animation-delay: 0.3s;
  }
  span:nth-child(3) {
    animation-delay: 0.6s;
  }
}
.logo-font {
  font-family: "Barriecito", "Livvic", sans-serif;
  font-size: 120%;
}
@keyframes slide-in-top {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}
@keyframes tracking-in-contract--mini {
  0% {
    letter-spacing: 0.05em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

@keyframes blink--home {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
