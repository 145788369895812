@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}
$min-width: 320px;
$max-width: 1920px;

@mixin fluid-prop($prop, $min-vw, $max-vw, $min-size, $max-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-size);
  $u4: unit($max-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      #{$prop}: $min-size;
      @media screen and (min-width: $min-vw) {
        #{$prop}: calc(
          #{$min-size} + #{strip-unit($max-size - $min-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        #{$prop}: $max-size;
      }
    }
  }
}
.loading {
  @include fluid-prop("font-size", $min_width, $max_width, 64px, 150px);
  font-size: 800;
  font-family: "Barriecito", "Livvic", sans-serif;
  position: absolute;
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 1.4s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-delay: 0.3s;
  top: 15%;
  bottom: 15%;
  left: 5%;
  right: 5%;
  margin: auto;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    top: 66vh;
    bottom: unset;
  }
  span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  span:nth-child(2) {
    animation-delay: 0.3s;
  }
  span:nth-child(3) {
    animation-delay: 0.6s;
  }
}

.loading-animation-container {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.loading-animation {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.loading-animation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 50rem;
  height: 50rem;
  max-width: 100vw;
  max-height: 100vw;

  @media screen and (max-width: 768px) {
    width: 20rem;
    height: 20rem;
  }
}

.loading-animation div {
  width: 5rem;
  height: 5rem;
  border: 5px solid #fff;
  background-color: #fff;
  @media screen and (max-width: 768px) {
    width: 2rem;
    height: 2rem;
    border: 2px solid #fff;
  }
}
@keyframes blink {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
