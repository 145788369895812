.genre-toggle {
  display: block;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.muted * {
  color: rgba(0, 0, 0, 0.12) !important;
}
.genre-toggle--1 {
}
.genre-toggle--2 {
}
