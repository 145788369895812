@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}
$min-width: 320px;
$max-width: 1920px;

@mixin fluid-prop($prop, $min-vw, $max-vw, $min-size, $max-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-size);
  $u4: unit($max-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      #{$prop}: $min-size;
      @media screen and (min-width: $min-vw) {
        #{$prop}: calc(
          #{$min-size} + #{strip-unit($max-size - $min-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        #{$prop}: $max-size;
      }
    }
  }
}

.app {
  min-height: 100vh;
  transition: background 1s;
  padding: 2% 5%;
  background-color: white;
  background-color: rgba(253, 208, 67, 0.7);
  background-image: linear-gradient(
    325deg,
    rgba(253, 208, 67, 0.2) 0%,
    rgba(226, 89, 139, 0.7) 100%
  );
  &.app--unpopulated {
    min-height: 100vh;
    background-color: rgba(253, 208, 67, 0.7);
    background-image: linear-gradient(
      325deg,
      rgba(253, 208, 67, 0.2) 0%,
      rgba(226, 89, 139, 0.7) 100%
    );
  }

  .user-info,
  .dashboard {
    background-color: white;
    border-radius: 5px;
    max-width: 1200px;
    padding: 2vw;
  }
  .user-info {
    margin-bottom: 3vh;
    box-shadow: 0 12px 25px -6px rgba(0, 0, 0, 0.25) !important;
  }
  .chart-container,
  .dashboard {
    margin: 3vh auto 0;
    box-shadow: 0 12px 25px -6px rgba(0, 0, 0, 0.25) !important;
    &,
    & * {
      animation: fade-in 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }
    .recharts-responsive-container {
      margin-left: -1vw;
      @media screen and (max-width: 768px) {
        margin-left: -3vw;
      }
    }
  }
}

.user-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 0;
  padding-bottom: 15px;

  p {
    margin: 0;
  }
}
.user-info__username {
  @include fluid-prop("font-size", $min_width, $max_width, 24px, 28px);
  text-transform: uppercase;
  font-weight: 800;
  font-family: "Barriecito", "Livvic", sans-serif;
}
.user-info__dates {
  display: flex;
  justify-content: space-between;
}
.user-info__more-info {
  @include fluid-prop("font-size", $min_width, $max_width, 18px, 20px);
  font-family: "Barriecito", "Livvic", sans-serif;

  .genre {
    color: #fd8b7b;
  }
  .genre2 {
    color: #7f4782;
  }
}
.user-info__zookeeprr-info {
  @include fluid-prop("font-size", $min_width, $max_width, 22px, 28px);
  font-family: "Livvic", sans-serif;
  text-align: center;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  color: rgba(226, 89, 139, 1);
  max-width: 900px;
  margin: auto;
  background-info: rgba(253, 208, 67, 0.1);
  border-info: 3px solid rgba(253, 208, 67, 1);
  p {
    line-height: 1;
    margin: 5px auto;
  }
  .judgement-free {
    margin-top: 10px;
    font-size: 75%;
    .shh {
      font-size: 75%;
    }
  }
}
.muted *,
.muted {
  color: rgba(0, 0, 0, 0.12);
}
@keyframes text-focus-in {
  0% {
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
