@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}
$min-width: 320px;
$max-width: 1920px;
@mixin fluid-prop($prop, $min-vw, $max-vw, $min-size, $max-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-size);
  $u4: unit($max-size);
  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      #{$prop}: $min-size;
      @media screen and (min-width: $min-vw) {
        #{$prop}: calc(
          #{$min-size} + #{strip-unit($max-size - $min-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        #{$prop}: $max-size;
      }
    }
  }
}
.main-header__inner {
  position: fixed;
  bottom: 3vw;
  right: 3vw;
  top: auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 100;
  @media screen and (max-width: 768px) {
    left: 3vw;
  }
}
.introduce-message {
  @media screen and (min-width: 768px) {
    transform: translate(-30vw, -30vh);
    transform-origin: 100% 100%;
    opacity: 1;
    animation: introduce-message 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
}
.main-header__bottom {
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  @media screen and (max-width: 768px) {
    padding: 10px 0 0;
    width: 100%;
  }
  .help-title,
  .help-subtext {
    margin-top: 0;
    line-height: 1.1;
    font-weight: 600;
    @include fluid-prop("font-size", $min_width, $max_width, 18px, 28px);
  }
  .help-subtext {
    font-weight: 400;
    @include fluid-prop("font-size", $min_width, $max_width, 14px, 18px);
  }
  p {
    margin: 0;
    @include fluid-prop("font-size", $min_width, $max_width, 18px, 22px);
  }
  p.help-link {
    @include fluid-prop("font-size", $min_width, $max_width, 16px, 20px);
  }
}
.input-wrapper {
  padding: 10px 0;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
}
.input-wrapper--horizontal {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 480px) {
    padding: 1vh 0;
  }
  & > label {
    flex-basis: 33%;
  }
  & > div {
    flex-basis: 66%;
    * {
      @include fluid-prop("font-size", $min_width, $max_width, 18px, 34px);
    }
  }
}
div.input-wrapper--checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-basis: 100%;
  padding: 0;
}
.input-wrapper--end-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  & > div {
    width: 100%;
  }
}
.nav {
  position: absolute;
  background-color: #ffffff;
  padding: 20px 50px 40px;
  transform: scale(0);
  display: flex;
  flex-direction: column;
  width: 760px;
  max-width: 85vw;
  justify-content: space-between;
  box-shadow:
    0 3px 10px rgba(0, 0, 0, 0.12),
    0 3px 10px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  bottom: 100%;
  right: 8vw;
  z-index: 100;
  @include fluid-prop("right", $min_width, $max_width, 60px, 100px);
  &:focus,
  &:hover {
    box-shadow:
      0 14px 28px rgba(0, 0, 0, 0.25),
      0 10px 10px rgba(0, 0, 0, 0.22);
  }
  &.nav--uncollapsed {
    animation: slide-in-blurred-right 0.3s cubic-bezier(0.55, 0.06, 0.68, 0.19)
      both;
  }
  &.nav--collapsed {
    animation: slide-out-blurred-right 0.3s
      cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
  }
  @media screen and (max-width: 768px) {
    position: fixed;
    top: auto;
    bottom: 33vw;
    left: 0;
    right: 0;
    margin-left: 0vw;
    min-width: 100vw;
    padding: 5vw;
  }
}
.help-link--use-mine {
  animation: jello-horizontal 1s both 3;
}
.use-mine {
  box-shadow:
    0 1px 3px rgba(255, 255, 255, 0.12),
    0 1px 2px rgba(255, 255, 255, 0.24);
  background-color: rgba(170, 92, 159, 0.2);
  border: 2px solid rgba(127, 71, 130, 0.2);
  line-height: 2;
  padding: 2px 5px;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  background-color: rgba(253, 139, 123, 1);
  border-color: rgba(226, 89, 139, 1);
  @include fluid-prop("font-size", $min_width, $max_width, 22px, 36px);
  &:focus,
  &:hover {
    background-color: white;
    cursor: pointer;
    color: rgba(127, 71, 130, 0.2);
    color: rgba(226, 89, 139, 1);
  }
}
button.nav__back-btn,
button.nav__toggle-btn {
  z-index: 2000;
  align-self: flex-end;
  cursor: pointer;
  background-color: #e2598b;
  display: inline-block;
  border: none;
  margin-left: 30px;
  border-radius: 50%;
  @include fluid-prop("width", $min_width, $max_width, 60px, 100px);
  @include fluid-prop("height", $min_width, $max_width, 60px, 100px);
  @include fluid-prop("min-width", $min_width, $max_width, 60px, 100px);
  @include fluid-prop("min-height", $min_width, $max_width, 60px, 100px);
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  transition: margin 1s cubic-bezier(0.25, 0.8, 0.25, 1) transform 0.4s
    cubic-bezier(0.25, 0.8, 0.25, 1) box-shadow 0.4s
    cubic-bezier(0.25, 0.8, 0.25, 1) background-color 0.3s
    cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: scale3d(1, 1, 1);
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
  &.animated {
    @media screen and (min-width: 768px) {
      animation: jello-horizontal 1s both 3;
    }
  }
  &:focus,
  &:hover {
    background-color: #aa5c9f;
    cursor: pointer;
    box-shadow:
      0 14px 28px rgba(0, 0, 0, 0.25),
      0 10px 10px rgba(0, 0, 0, 0.22);
    transform: scale(1.01);
  }
  svg {
    min-width: 75%;
    min-height: 75%;
  }
}
button.nav__toggle-btn {
  svg {
    transform: scale(1);
    margin-top: 0;
    transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  &:focus,
  &:hover {
    svg {
      transform: scale(1.33);
    }
  }
}
button.nav__back-btn {
  svg {
    transform: scale(1);
    margin-left: 0;
    transition:
      transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1),
      margin-left 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  &:focus,
  &:hover {
    svg {
      transform: scale(1.33);
      margin-left: -33%;
    }
  }
}
button.submit-btn {
  background-color: #fdd043;
  border: 3px solid #fdd043;
  color: #333;
  font-weight: 600;
  min-height: 50px;
  @include fluid-prop("font-size", $min_width, $max_width, 18px, 28px);
  margin-top: 20px;
  border-radius: 0;
  &:focus,
  &:hover {
    cursor: pointer;
    border: 3px solid #aa5c9f;
    background-color: #aa5c9f;
    color: #f3f3f3;
  }
}
.nav__date-pickers {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
  @media screen and (max-width: 480px) {
    padding: 1vh 0;
  }
  & > * {
    flex-basis: 47%;
  }
}
.username-input {
  @include fluid-prop("font-size", $min_width, $max_width, 18px, 34px);
}
.nav__username {
  @include fluid-prop("font-size", $min_width, $max_width, 22px, 34px);
  font-weight: 600;
  .required-reminder {
    display: none;
  }
}
.nav__heading {
  font-weight: 600;
  line-height: 1.1;
  margin: 5px 0;
  @include fluid-prop("font-size", $min_width, $max_width, 18px, 34px);
  display: block;
  .header-help-link {
    @include fluid-prop("font-size", $min_width, $max_width, 16px, 20px);
  }
}
.nav__username--invalid {
  .required-reminder {
    display: inline-block;
    color: #e2598b;
  }
  label {
    color: #e2598b;
  }
  input {
    border: 1px solid #e2598b;
  }
}
.nav__heading--username {
  transition: color 0.4s;
}
*.atn {
  label {
    color: #e2598b;
  }
  input {
    border-color: #e2598b !important;
  }
}
*.atn--anim > * {
  animation: heartbeat 1.5s ease-in-out both;
}
.username-input {
  transition: border-color 0.4s !important;
}
@keyframes introduce-message {
  0% {
    transform: scale(1.4);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}
@keyframes slide-in-blurred-right {
  0% {
    transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    transform-origin: 0% 50%;
    filter: blur(20px);
    opacity: 0;
  }
  20% {
    filter: blur(10px);
  }
  50% {
    filter: blur(0);
  }
  100% {
    transform: translateX(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-out-blurred-right {
  0% {
    transform: translateX(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
  100% {
    transform: translateX(1000px) scaleX(2) scaleY(0.2);
    transform-origin: 0% 50%;
    filter: blur(40px);
    opacity: 0;
  }
}
