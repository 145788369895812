@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}
$min-width: 320px;
$max-width: 1920px;

@mixin fluid-prop($prop, $min-vw, $max-vw, $min-size, $max-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-size);
  $u4: unit($max-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      #{$prop}: $min-size;
      @media screen and (min-width: $min-vw) {
        #{$prop}: calc(
          #{$min-size} + #{strip-unit($max-size - $min-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        #{$prop}: $max-size;
      }
    }
  }
}
.dashboard {
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  max-width: 1300px;
  margin: auto;
}
.dashboard__module {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  position: relative;
  cursor: pointer;
  margin: 2%;
  padding: 30px;
  color: #333;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
  flex-grow: 1;
  width: 29%;
  @media screen and (max-width: 900px) {
    width: 46%;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
  }

  animation-name: dashboard-boxes;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  &:nth-of-type(1n) {
    background-color: rgba(170, 92, 159, 0.7);
  }
  &:nth-of-type(2n) {
    background-color: rgba(253, 208, 67, 0.7);
    animation-delay: 0.1s;
  }

  &:nth-of-type(3n) {
    background-color: rgba(253, 139, 123, 0.7);
    animation-delay: 0.2s;
  }
  &:nth-of-type(4n) {
    background-color: rgba(226, 89, 139, 0.7);
    animation-delay: 0.3s;
  }
  &:hover {
    box-shadow:
      0 14px 28px rgba(0, 0, 0, 0.25),
      0 10px 10px rgba(0, 0, 0, 0.22);
    transform: translateY(-10px) scale(1.01);
    filter: saturate(120%);
  }
  * {
    position: relative;
    z-index: 3;
  }
}
.dashboard__module__heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: row;
  }
}
.dashboard__module__title {
  @include fluid-prop("font-size", $min_width, $max_width, 32px, 42px);
  text-align: center;
  font-weight: 600;
  font-family: "Barriecito", "Livvic", sans-serif;
  margin: 0;
  line-height: 1;
}
.dashboard__module__subtitle {
  @include fluid-prop("font-size", $min_width, $max_width, 22px, 36px);
  text-align: center;
  font-weight: 200;
  margin: 0;
  line-height: 1;
}
.dashboard__module__image {
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  @media screen and (max-width: 768px) {
    padding-bottom: 0;
    width: 33%;
  }
  svg {
    min-width: 75%;
    min-height: 70px;
    margin: auto;
  }
}
.chart-subheading__friday {
  display: inline-block;
  width: 60px;
}

@keyframes dashboard-boxes {
  0% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-50px) scale(1.01);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}
