@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}
$min-width: 320px;
$max-width: 1920px;

@mixin fluid-prop($prop, $min-vw, $max-vw, $min-size, $max-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-size);
  $u4: unit($max-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      #{$prop}: $min-size;
      @media screen and (min-width: $min-vw) {
        #{$prop}: calc(
          #{$min-size} + #{strip-unit($max-size - $min-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        #{$prop}: $max-size;
      }
    }
  }
}

.error-page-wrapper,
.home__header--error {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  min-width: 100vw;
}
.error-page-wrapper {
  background-color: white;
}
.server-message {
  @include fluid-prop("font-size", $min_width, $max_width, 18px, 36px);
  line-height: 1;
  text-align: center;
  color: #7f4782;
  margin-bottom: 0;
  margin-top: 20px;
  padding: 0 5%;
  &.server-message--code {
    code {
      @include fluid-prop("font-size", $min_width, $max_width, 15px, 24px);
      margin-top: 10px;
      white-space: pre-wrap;
      word-break: unset;
    }
  }
}
.css-logo--error {
  line-height: 1.1;
  letter-spacing: -12px;
}
